<template>
  <div>
    <div class="contentv">
      <div class="navtab">
        <div class="dic_flex">
          <div class="loginmain">
            <img class="logoimg" src="../assets/images/diclog.png" />
          </div>
          <div class="menudiv">
            <el-menu
              ref="menu"
              :default-active="activeIndex"
              mode="horizontal"
              :router="true"
              menu-trigger="hover"
              :unique-opened="true"
              text-color="#000"
              active-text-color="#004bb2"
            >
              <el-menu-item index="/home">
                <template>
                  <span>首页</span>
                </template>
              </el-menu-item>

              <el-submenu index="/resultsinfo">
                <template slot="title">解决方案</template>
                <div class="res">
               
                  <div class="res1">
                       <div class="restitles">数字政法</div>
                    <el-menu-item index="/resultDetails/1">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp">&#xe602;</i>行政执法</span>
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/2">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe640;</i
                          >交通执法</span
                        >
                      </template>
                    </el-menu-item>
                  </div>
                  <div class="res1">
                       <div class="restitles">数字公安</div>
                    <el-menu-item index="/resultDetails/3">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            >&#xe64b;</i
                          >治安要素管控</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/4">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe637;</i
                          >舆情预警管控</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/5">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe62b;</i
                          >找堵防</span
                        >
                      </template>
                    </el-menu-item>
                    <!-- <el-menu-item index="/resultDetails/6">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe636;</i
                          >流动人口管控</span
                        >
                      </template>
                    </el-menu-item> -->
                    <el-menu-item index="/resultDetails/11">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe636;</i
                          >基础管控中心</span
                        >
                      </template>
                    </el-menu-item>
                  </div>
                  <div class="res1">
                       <div class="restitles">数字电网</div>
                    <el-menu-item index="/resultDetails/7">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe663;</i
                          >电力营销系统</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/8">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe650;</i
                          >电力计量系统</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/9">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            > &#xe62b;</i
                          >自助服务终端</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/10">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            >&#xe664;</i
                          >电力三防</span
                        >
                      </template>
                    </el-menu-item>
                    <el-menu-item index="/resultDetails/12">
                      <template>
                        <span class="mtitle"
                          ><i class="iconfont icocp"
                            >&#xe650;</i
                          >电力大数据</span
                        >
                      </template>
                    </el-menu-item>
                  </div>
                </div>
              </el-submenu>
              <el-menu-item index="/parkinfo">
                <template>
                  <span>经典案例</span>
                </template>
              </el-menu-item>
              <!-- <el-submenu index="/team">
              <template slot="title">技术团队</template>
              <el-menu-item index="/team">概况信息</el-menu-item>
              <el-menu-item index="/teamExpert">特聘专家</el-menu-item>
              <el-menu-item index="/organList">科创机构</el-menu-item>
              <el-menu-item index="/teamList">创新团队</el-menu-item>
              <el-menu-item index="/parkList">园区企业</el-menu-item>
            </el-submenu> -->
              <!-- <el-submenu index="/notice">
              <template slot="title">新闻中心</template>
              <el-menu-item index="/notice">通知公告</el-menu-item>
              <el-menu-item index="/news">成果新闻</el-menu-item>
              <el-menu-item index="/aboutus">政策新闻</el-menu-item>
            </el-submenu> -->
              <el-menu-item index="/notice">
                <template>
                  <span>新闻中心</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/about">
                <template>
                  <span>关于我们</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/serviceinfo">
                <template>
                  <span>人才招聘</span>
                </template>
              </el-menu-item>
              <!-- <el-submenu index="/about">
              <template slot="title">关于我们</template>
              <el-menu-item index="/about">关于我们</el-menu-item>
              <el-menu-item index="/roomprofile">领域简介</el-menu-item>
              <el-menu-item index="/aboutmessage">合作留言</el-menu-item>
              <el-menu-item index="/aboutphone">联系方式</el-menu-item>
            </el-submenu> -->
              <!-- <el-submenu  v-if="isLogin==1">
              <template slot="title">{{username}}</template>
              <el-menu-item @click="tourl(1)">个人中心</el-menu-item>
            </el-submenu> -->
            </el-menu>
          </div>
        </div>
        <div class="rightinfo">
          <div class="backinfo" @click="getDialog">客户服务</div>

          <div class="backinfo" @click="getDialog">咨询与试用</div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>
<script>
import local from "../utils/local";
import api from "@/config/api";
import { getRequst, postRequest } from "@/api/index";
 import Dialog from '@/components/Dialog.vue'
export default {
   components: {
     Dialog
   },
  data() {
    return {
      activeIndex: "1",
      isLogin: "",
      username: "当前用户",
    };
  },
  created() {},

  methods: {
    getDialog(){
       this.$refs.dialog.openDialog()
    },
    //1会员中心2退出登陆
    tourl(type) {
      if (type == 1) {
        window.location.href = api.link_url + "/manager.html";
      } else if (type == 2) {
        sessionStorage.setItem("isLogin", 0);
        postRequest(api.logout, {}).then((res) => {
          this.$router.push({
            path: "/home",
            query: {},
          });
        });
      }
    },
    //供需交流
    toneed() {
      if (
        !sessionStorage.getItem("isLogin") ||
        sessionStorage.getItem("isLogin") == 0
      ) {
        // window.location.href = api.link_url+'/login.html'
        this.$confirm("当前未登陆,是否前往登陆?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            // this.$message({
            //   type: 'success',
            //   message: '删除成功!'
            // });
            window.location.href = api.link_url + "/login.html";
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
      } else if (sessionStorage.getItem("isLogin") == 1) {
        window.location.href = api.link_url + "/manager.html";
      }
    },

    //登陆页面
    tologin(type) {
      if (type == 1) {
        if (
          !sessionStorage.getItem("isLogin") ||
          sessionStorage.getItem("isLogin") == 0
        ) {
          window.location.href = api.link_url + "/login.html";
        } else if (sessionStorage.getItem("isLogin") == 1) {
          window.location.href = api.link_url + "/manager.html";
        }
      } else {
        this.$router.push({
          path: "/register",
          query: {},
        });
      }
    },
    initMenuActive(routePath) {
      this.activeIndex = routePath;
    },
  },
  watch: {
    // 监听路由变化
    "$route.path": {
      handler(routePath) {
        this.initMenuActive(routePath);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.htext:hover {
  // color: #f00;
  text-decoration: underline;
  cursor: pointer;
}
.contentv {
  position: relative;
  top: 0px;
  width: 100%;
  z-index: 999;

  background-color: #ffffff;
  border-bottom: none;
}
.el-menu--popup {
  width: 100px !important;
}

.menudiv /deep/ .el-menu-item {
  text-align: center;
  padding: 0 30px !important;
}
.el-submenu /deep/ .el-submenu__title {
  font-size: 16px !important;
  color: #333 !important;
  // margin-top:2px;
}
.el-submenu /deep/ .el-submenu__title i {
  font-size: 16px !important;
  color: #333 !important;
}
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent;
  color: #2b72ad !important;
}
/deep/
  .el-menu--horizontal
  > .el-submenu
  .el-submenu__title:hover
  .el-submenu__icon-arrow {
  color: #2b72ad !important;
}
// .is-active{
//   font-size: 16px;
// }
.header {
  width: 100%;
  height: 100px;
  position: relative;
}

.navtab {
  // background-color: #fff;
  // overflow-x: auto;
  // padding-bottom: 4px;
  // display: flex;
  justify-content: space-between;
  //     padding: 20px;
  // border-bottom: 1px solid #ddd;
  background-color: transparent;
  // overflow-x: auto;
  padding-bottom: 4px;
  display: flex;
  // justify-content: space-around;
  padding: 20px 0 0;
  /* border-bottom: 1px solid #ddd; */
  width: 1280px;
  margin: 0 auto;
  align-items: center;
  span {
    font-size: 16px;
    color: #333;
  }
  span:hover {
    color: #2b72ad !important;
  }
  div {
    font-size: 16px;
    color: #333;
  }
}

.el-menu {
  width: 100%;
  margin: auto;
  border-bottom: none !important;
  display: flex;
  justify-content: space-between;
  background: none !important;
  align-items: center;
}

.menu-item {
  background-color: none;
  width: 80%;
}

.main {
  width: 1180px;
  margin: auto;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.loginmain {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .logoimg {
    width: 68px;
    height: auto;
    margin-top: 5px;
  }
  .centerdiv {
    font-size: 24px;
    font-weight: bold;
    color: #00a0e9;
    padding-left: 10px;
  }
}

.ctitle {
  height: 60px;
  width: 1180px;
  margin: auto;
  font-size: 14px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.infotext {
  line-height: 60px;
  color: #333;
  a {
    color: #333;
  }
}

.rightinfo {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100px;
}

.backinfo {
  color: #00428e;
  // background-color: #306dac;
  height: 32px;
  // width: 48px;
  line-height: 32px;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
  font-size: 14px !important;
  margin-left: 25px;
}
.menudiv {
  display: flex;
  justify-content: flex-end;
}

element.style {
  color: rgb(48, 109, 172);
  border-bottom-color: rgb(48, 109, 172);
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}
/deep/ .el-menu--horizontal > .el-menu-item {
  position: relative;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active:before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 50px;
  height: 2px;
  background: #2b72ad;
  left: 50%;
  margin-left: -25px;
  border-bottom: none !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active span {
  color: #2b72ad !important;
}
/deep/ .el-menu-item {
  padding: 0 30px !important;
}
.res {
  background: #004bb2;
  width: 600px;
  height: 350px;
  padding: 30px;
  display: flex;
}
.res1 {
  width: 33.3%;
}
.res /deep/ .el-menu-item {
  width: 100%;
  margin-bottom: 12px;
}
.mtitle {
  font-size: 16px;
  color:#ffffff;
}
.icocp {
      display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -3px;
    font-size: 20px;
    color: #ffffff;
}
.backinfo {
  background: #2b72ad;
  color: #ffffff !important;
  padding: 0 10px;
  font-size: 14px;
}
.restitles{
  text-align: center;
  margin-bottom:15px;
  font-size: 18px;
  border-bottom:1px rgba(255,255,255,.2) solid;
  padding-bottom:15px;
  color:#ffffff;
}
:v-deep .el-menu--popup{
  background: #004bb2;
  padding:0!important;
}
.el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title{
  color:#ffffff!important;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
  background: #004bb2!important;
}
</style>
